import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, combineLatest } from 'rxjs';
import { map, tap, switchMap, take, finalize } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    // CURRENT_USER = 'current_user';
    // HAS_LOGGED_IN = 'hasLoggedIn';
    // HAS_SEEN_TUTORIAL = 'hasSeenTutorial';

    constructor(
        private afs: AngularFirestore,
        private afAuth: AngularFireAuth,
        private afStorage: AngularFireStorage,
        private storage: Storage,
        private http: HttpClient) {
    }

    getCurrentUser = () => {
        return this.afAuth.authState.pipe(
            switchMap(auth => {
                return auth && auth.phoneNumber ? this.getUser(auth.phoneNumber) : of(null);
            }),
            map(user => {
                return user;
            })
        );
    }

    getAuthState = () => {
        return this.afAuth.authState;
    }

    getNewId = () => this.afs.createId();

    getFields(): Observable<any> {
        return this.http.get('./assets/json/user.json');
    }

    getUsers(): Observable<any[]> {
        return this.afs.collection('users').valueChanges().pipe(
            // tap(data => console.log('Users', data))
        );
    }

    addNewUser(user) {
        user.id = this.afs.createId();
        user.date_time = (new Date()).getTime();
        user.timestamp = (new Date()).getTime();

        return this.updateUser(user);
    }

    async updateUser(user) {
        return await this.afs.collection('users').doc(user.id).set(user);
    }

    deleteUser(user) {
        return this.afs.collection('users').doc(user.id).delete().then(() => {
            return Promise.resolve(user);
        });
    }

    getUser(id): Observable<any> {
        return this.afs.doc(`users/${id}`).valueChanges().pipe(
            // tap(data => console.log('User', data))
        );
    }

    getUserByPhone(id) {
        return this.afs.doc(`users/${id}`).valueChanges().pipe(
            // tap(data => console.log('User', data))
        );
    }

    async signup(user: any): Promise<any> {
        user.id = user.phone;
        user.date_time = (new Date()).getTime();
        user.timestamp = (new Date()).getTime();

        await this.updateUser(user);
        // await this.storage.set(this.CURRENT_USER, user.id);
        // await this.storage.set(this.HAS_LOGGED_IN, true);
        return await this.setPhone(user.phone);
    }

    setPhone(phone: string): Promise<any> {
        return this.storage.set('phone', phone);
    }

    login(user: any) {
        this.getUser(user.phone).subscribe(usr => {
            if (!usr) {
                this.updateUser(user);
            }
            this.setPhone(usr.phone);
        });
    }

    async logout(): Promise<any> {
        return await this.afAuth.auth.signOut()
        // .then(() => this.storage.remove(this.HAS_LOGGED_IN))
        // .then(() => this.storage.remove('phone'));;
    }

    uploadFile(event) {
        return this.getCurrentUser().pipe(
            take(1),
            switchMap(user => {
                const file = event.target.files[0];
                const filePath = user.phone;
                const fileRef = this.afStorage.ref(filePath);
                const task = this.afStorage.upload(filePath, file);

                let downloadURL;

                // observe percentage changes
                const uploadPercent$ = task.percentageChanges();
                // get notified when the download URL is available
                const url$ = task.snapshotChanges().pipe(finalize(() => downloadURL = fileRef.getDownloadURL()));

                return combineLatest([url$, uploadPercent$]);
            })
        );
    }
}
