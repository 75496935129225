import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { MenuController, Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';

import { UserData } from './providers/user-data';
import { UserService } from './providers/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  ownerTruckPages = [
    {
      title: 'New Truck',
      url: '/owner/truck/tabs/trucks/create',
      icon: 'add'
    },
    {
      title: 'Search Loads',
      url: '/owner/truck/tabs/loads/list',
      icon: 'search'
    },
    {
      title: 'Saved Loads',
      url: '/owner/truck/tabs/loads/saved',
      icon: 'calendar'
    },
    {
      title: 'My Posted Trucks',
      url: '/owner/truck/tabs/trucks/list',
      icon: 'car'
    },
    {
      title: 'Loads Map',
      url: '/owner/truck/tabs/loads/map',
      icon: 'location'
    },

  ];

  ownerEquipmentPages = [
    {
      title: 'New Equipment',
      url: '/owner/equipment/tabs/equipment/create',
      icon: 'add'
    },
    {
      title: 'Search Jobs',
      url: '/owner/equipment/tabs/jobs/list',
      icon: 'search'
    },
    {
      title: 'Saved Jobs',
      url: '/owner/equipment/tabs/jobs/saved',
      icon: 'calendar'
    },
    {
      title: 'My Equipment',
      url: '/owner/equipment/tabs/equipment/list',
      icon: 'car'
    },
    {
      title: 'Jobs Map',
      url: '/owner/equipment/tabs/jobs/map',
      icon: 'location'
    },

  ];

  brokerTruckPages = [
    {
      title: 'New Load',
      url: '/broker/truck/tabs/loads/create',
      icon: 'add'
    },
    {
      title: 'Search Trucks',
      url: '/broker/truck/tabs/trucks/list',
      icon: 'search'
    },
    {
      title: 'Saved Trucks',
      url: '/broker/truck/tabs/trucks/saved',
      icon: 'car'
    },
    {
      title: 'My Posted Loads',
      url: '/broker/truck/tabs/loads/list',
      icon: 'calendar'
    },
    // {
    //   title: 'Trucks Map',
    //   url: '/broker/tabs/trucks/map',
    //   icon: 'location'
    // },
  ];

  brokerEquipmentPages = [
    {
      title: 'New Job',
      url: '/broker/equipment/tabs/jobs/create',
      icon: 'add'
    },
    {
      title: 'Search Equipment',
      url: '/broker/equipment/tabs/equipment/list',
      icon: 'search'
    },
    {
      title: 'Saved Equipment',
      url: '/broker/equipment/tabs/equipment/saved',
      icon: 'car'
    },
    {
      title: 'My Posted Jobs',
      url: '/broker/equipment/tabs/jobs/list',
      icon: 'calendar'
    },
    {
      title: 'Equipment Map',
      url: '/broker/equipment/tabs/equipment/map',
      icon: 'location'
    }
  ];
  loggedIn = false;
  dark = false;
  user: any;
  account: any;

  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private userService: UserService
  ) {
    this.initializeApp();
  }

  async ngOnInit() {
    this.checkLoginStatus();
    this.listenForLoginEvents();

    this.swUpdate.available.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Update available!',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'Reload'
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });

    this.userService.getCurrentUser().subscribe(user => {
      console.log(user);
      this.user = user;

      switch (this.user.accountType) {
        case 'owner-truck': {
          this.account = {
            title: 'Account',
            url: '/owner/truck/tabs/account',
            icon: 'information-circle'
          }
          break;
        }
        case 'owner-equipment': {
          this.account = {
            title: 'Account',
            url: '/owner/equipment/tabs/account',
            icon: 'information-circle'
          }
          break;
        }
        case 'broker-loads': {
          this.account = {
            title: 'Account',
            url: '/broker/truck/tabs/account',
            icon: 'information-circle'
          }
          break;
        }
        case 'broker-equipment': {
          this.account = {
            title: 'Account',
            url: '/broker/equipment/tabs/account',
            icon: 'information-circle'
          }
          break;
        }
      }
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  checkLoginStatus() {
    return this.userData.isLoggedIn().then(loggedIn => {
      return this.updateLoggedInStatus(loggedIn);
    });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  async logout() {
    console.log('logout');
    await this.userService.logout();
    this.router.navigateByUrl('/login');
  }

  openTutorial() {
    this.menu.enable(false);
    this.storage.set('ion_did_tutorial', false);
    if (this.user && this.user.accountType === 'broker') {
      this.router.navigateByUrl('/tutorial-broker');
    } else {
      this.router.navigateByUrl('/tutorial-trucker');
    }
  }
}
