import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { CheckTutorial } from './providers/check-tutorial.service';
// import { CheckUserGuard } from './providers/check-user.guard';
// import { CheckProfileGuard } from './providers/check-profile.guard';
// import { CheckSubscriptionGuard } from './providers/check-subscription.guard';
// import { CheckBrokerAccountTypeGuard } from './providers/check-broker-account-type.guard';
// import { CheckTruckerAccountTypeGuard } from './providers/check-trucker-account-type.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  // {
  //   path: 'account',
  //   loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
  // },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    // canLoad: [CheckUserGuard]
  },
  // {
  //   path: 'app',
  //   loadChildren: () => import('./pages/tabs-page/tabs-page.module').then(m => m.TabsModule),
  //   canActivate: [CheckProfileGuard]
  // },
  {
    path: 'broker/truck',
    loadChildren: () => import('./pages/tabs-broker-truck/tabs-broker-truck.module').then(m => m.TabsBrokerTruckPageModule),
    // canLoad: [CheckBrokerAccountTypeGuard]
  },
  {
    path: 'broker/equipment',
    loadChildren: () => import('./pages/tabs-broker-equipment/tabs-broker-equipment.module').then(m => m.TabsBrokerEquipmentPageModule),
    // canLoad: [CheckBrokerAccountTypeGuard]
  },
  {
    path: 'owner/truck',
    loadChildren: () => import('./pages/tabs-owner-truck/tabs-owner-truck.module').then(m => m.TabsOwnerTruckPageModule),
    // canLoad: [CheckTruckerAccountTypeGuard]
  },
  {
    path: 'owner/equipment',
    loadChildren: () => import('./pages/tabs-owner-equipment/tabs-owner-equipment.module').then(m => m.TabsOwnerEquipmentPageModule),
    // canLoad: [CheckTruckerAccountTypeGuard]
  },
  // {
  //   path: 'tutorial',
  //   loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialModule),
  //   canLoad: [CheckTutorial]
  // },
  {
    path: 'truck-list',
    loadChildren: () => import('./pages/truck-list/truck-list.module').then(m => m.TruckListPageModule)
  },
  {
    path: 'truck-detail',
    loadChildren: () => import('./pages/truck-detail/truck-detail.module').then(m => m.TruckDetailPageModule)
  },
  {
    path: 'load-detail',
    loadChildren: () => import('./pages/load-detail/load-detail.module').then(m => m.LoadDetailPageModule)
  },
  {
    path: 'load-list',
    loadChildren: () => import('./pages/load-list/load-list.module').then(m => m.LoadListPageModule)
  },
  {
    path: 'load-delete',
    loadChildren: () => import('./pages/load-delete/load-delete.module').then(m => m.LoadDeletePageModule)
  },
  {
    path: 'load-post',
    loadChildren: () => import('./pages/load-post/load-post.module').then(m => m.LoadPostPageModule)
  },
  {
    path: 'truck-new',
    loadChildren: () => import('./pages/truck-new/truck-new.module').then(m => m.TruckNewPageModule)
  },
  {
    path: 'truck-post',
    loadChildren: () => import('./pages/truck-post/truck-post.module').then(m => m.TruckPostPageModule)
  },
  {
    path: 'truck-delete',
    loadChildren: () => import('./pages/truck-delete/truck-delete.module').then(m => m.TruckDeletePageModule)
  },
  {
    path: 'account-post',
    loadChildren: () => import('./pages/account-post/account-post.module').then(m => m.AccountPostPageModule),
  },
  {
    path: 'file-list',
    loadChildren: () => import('./pages/file-list/file-list.module').then(m => m.FileListPageModule)
  },
  {
    path: 'file-delete',
    loadChildren: () => import('./pages/file-delete/file-delete.module').then(m => m.FileDeletePageModule)
  },
  {
    path: 'file-detail',
    loadChildren: () => import('./pages/file-detail/file-detail.module').then(m => m.FileDetailPageModule)
  },
  {
    path: 'file-list',
    loadChildren: () => import('./pages/file-list/file-list.module').then(m => m.FileListPageModule)
  },
  {
    path: 'subscription-list',
    loadChildren: () => import('./pages/subscription-list/subscription-list.module').then(m => m.SubscriptionListPageModule)
  },
  {
    path: 'subscription-post',
    loadChildren: () => import('./pages/subscription-post/subscription-post.module').then(m => m.SubscriptionPostPageModule)
  },
  {
    path: 'subscription-detail',
    loadChildren: () => import('./pages/subscription-detail/subscription-detail.module').then(m => m.SubscriptionDetailPageModule)
  },
  {
    path: 'subscription-delete',
    loadChildren: () => import('./pages/subscription-delete/subscription-delete.module').then(m => m.SubscriptionDeletePageModule)
  },
  {
    path: 'saved-load-list',
    loadChildren: () => import('./pages/load-saved-list/load-saved-list.module').then(m => m.LoadSavedListPageModule)
  },
  {
    path: 'saved-truck-list',
    loadChildren: () => import('./pages/truck-saved-list/truck-saved-list.module').then(m => m.TruckSavedListPageModule)
  },
  {
    path: 'map-load-list',
    loadChildren: () => import('./pages/load-map-list/load-map-list.module').then(m => m.LoadMapListPageModule)
  },
  {
    path: 'map-truck-list',
    loadChildren: () => import('./pages/truck-map-list/truck-map-list.module').then(m => m.TruckMapListPageModule)
  },
  {
    path: 'load-posted-list',
    loadChildren: () => import('./pages/load-posted-list/load-posted-list.module').then(m => m.LoadPostedListPageModule)
  },
  {
    path: 'truck-posted-list',
    loadChildren: () => import('./pages/truck-posted-list/truck-posted-list.module').then(m => m.TruckPostedListPageModule)
  },
  {
    path: 'truck-posted-detail',
    loadChildren: () => import('./pages/truck-posted-detail/truck-posted-detail.module').then(m => m.TruckPostedDetailPageModule)
  },
  {
    path: 'load-posted-detail',
    loadChildren: () => import('./pages/load-posted-detail/load-posted-detail.module').then(m => m.LoadPostedDetailPageModule)
  },
  {
    path: 'queries',
    loadChildren: () => import('./pages/queries/queries.module').then(m => m.QueriesPageModule)
  },
  // {
  //   path: 'account-broker-truck',
  //   loadChildren: () => import('./pages/account-broker-truck/account-broker-truck.module').then(m => m.AccountBrokerTruckPageModule),
  //   canLoad: [CheckBrokerAccountTypeGuard]
  // },
  // {
  //   path: 'account-owner-truck',
  //   loadChildren: () => import('./pages/account-owner-truck/account-owner-truck.module').then(m => m.AccountOwnerTruckPageModule),
  //   canLoad: [CheckTruckerAccountTypeGuard]
  // },
  {
    path: 'tutorial-trucker',
    loadChildren: () => import('./pages/tutorial-trucker/tutorial-trucker.module').then(m => m.TutorialTruckerPageModule)
  },
  {
    path: 'tutorial-broker',
    loadChildren: () => import('./pages/tutorial-broker/tutorial-broker.module').then(m => m.TutorialBrokerPageModule)
  },
  {
    path: 'rate-check',
    loadChildren: () => import('./pages/rate-check/rate-check.module').then(m => m.RateCheckPageModule)
  },
  {
    path: 'tabs-owner-truck',
    loadChildren: () => import('./pages/tabs-owner-truck/tabs-owner-truck.module').then(m => m.TabsOwnerTruckPageModule)
  },
  {
    path: 'tabs-owner-equipment',
    loadChildren: () => import('./pages/tabs-owner-equipment/tabs-owner-equipment.module').then(m => m.TabsOwnerEquipmentPageModule)
  },
  {
    path: 'tabs-broker-equipment',
    loadChildren: () => import('./pages/tabs-broker-equipment/tabs-broker-equipment.module').then(m => m.TabsBrokerEquipmentPageModule)
  },
  {
    path: 'tabs-broker-truck',
    loadChildren: () => import('./pages/tabs-broker-truck/tabs-broker-truck.module').then(m => m.TabsBrokerTruckPageModule)
  },
  {
    path: 'equipment-list',
    loadChildren: () => import('./pages/equipment-list/equipment-list.module').then(m => m.EquipmentListPageModule)
  },
  {
    path: 'equipment-detail',
    loadChildren: () => import('./pages/equipment-detail/equipment-detail.module').then(m => m.EquipmentDetailPageModule)
  },
  {
    path: 'saved-job-list',
    loadChildren: () => import('./pages/job-saved-list/job-saved-list.module').then(m => m.JobSavedListPageModule)
  },
  {
    path: 'map-equipment-list',
    loadChildren: () => import('./pages/equipment-map-list/equipment-map-list.module').then(m => m.EquipmentMapListPageModule)
  },
  {
    path: 'saved-equipment-list',
    loadChildren: () => import('./pages/equipment-saved-list/equipment-saved-list.module').then(m => m.EquipmentSavedListPageModule)
  },
  {
    path: 'job-list',
    loadChildren: () => import('./pages/job-list/job-list.module').then(m => m.JobListPageModule)
  },
  {
    path: 'job-post',
    loadChildren: () => import('./pages/job-post/job-post.module').then(m => m.JobPostPageModule)
  },
  {
    path: 'job-posted-list',
    loadChildren: () => import('./pages/job-posted-list/job-posted-list.module').then(m => m.JobPostedListPageModule)
  },
  {
    path: 'job-posted-detail',
    loadChildren: () => import('./pages/job-posted-detail/job-posted-detail.module').then(m => m.JobPostedDetailPageModule)
  },
  {
    path: 'job-detail',
    loadChildren: () => import('./pages/job-detail/job-detail.module').then(m => m.JobDetailPageModule)
  },
  {
    path: 'job-delete',
    loadChildren: () => import('./pages/job-delete/job-delete.module').then(m => m.JobDeletePageModule)
  },
  {
    path: 'map-job-list',
    loadChildren: () => import('./pages/job-map-list/job-map-list.module').then(m => m.JobMapListPageModule)
  },
  {
    path: 'equipment-post',
    loadChildren: () => import('./pages/equipment-post/equipment-post.module').then(m => m.EquipmentPostPageModule)
  },
  {
    path: 'equipment-posted-list',
    loadChildren: () => import('./pages/equipment-posted-list/equipment-posted-list.module').then(m => m.EquipmentPostedListPageModule)
  },
  {
    path: 'equipment-delete',
    loadChildren: () => import('./pages/equipment-delete/equipment-delete.module').then(m => m.EquipmentDeletePageModule)
  },
  {
    path: 'equipment-saved-list',
    loadChildren: () => import('./pages/equipment-saved-list/equipment-saved-list.module').then(m => m.EquipmentSavedListPageModule)
  },
  {
    path: 'job-saved-list',
    loadChildren: () => import('./pages/job-saved-list/job-saved-list.module').then(m => m.JobSavedListPageModule)
  },
  {
    path: 'load-saved-list',
    loadChildren: () => import('./pages/load-saved-list/load-saved-list.module').then(m => m.LoadSavedListPageModule)
  },
  {
    path: 'truck-saved-list',
    loadChildren: () => import('./pages/truck-saved-list/truck-saved-list.module').then(m => m.TruckSavedListPageModule)
  },
  {
    path: 'job-map-list',
    loadChildren: () => import('./pages/job-map-list/job-map-list.module').then(m => m.JobMapListPageModule)
  },
  {
    path: 'equipment-map-list',
    loadChildren: () => import('./pages/equipment-map-list/equipment-map-list.module').then(m => m.EquipmentMapListPageModule)
  },
  {
    path: 'load-map-list',
    loadChildren: () => import('./pages/load-map-list/load-map-list.module').then(m => m.LoadMapListPageModule)
  },
  {
    path: 'truck-map-list',
    loadChildren: () => import('./pages/truck-map-list/truck-map-list.module').then(m => m.TruckMapListPageModule)
  },
  {
    path: 'truck-account-broker',
    loadChildren: () => import('./pages/truck-account-broker/truck-account-broker.module').then(m => m.TruckAccountBrokerPageModule)
  },
  {
    path: 'truck-account-owner',
    loadChildren: () => import('./pages/truck-account-owner/truck-account-owner.module').then(m => m.TruckAccountOwnerPageModule)
  },
  {
    path: 'equipment-account-broker',
    loadChildren: () => import('./pages/equipment-account-broker/equipment-account-broker.module').then(m => m.EquipmentAccountBrokerPageModule)
  },
  {
    path: 'equipment-account-owner',
    loadChildren: () => import('./pages/equipment-account-owner/equipment-account-owner.module').then(m => m.EquipmentAccountOwnerPageModule)
  },
  {
    path: 'equipment-posted-detail',
    loadChildren: () => import('./pages/equipment-posted-detail/equipment-posted-detail.module').then(m => m.EquipmentPostedDetailPageModule)
  },
  {
    path: 'job-search',
    loadChildren: () => import('./pages/job-search/job-search.module').then(m => m.JobSearchPageModule)
  },
  {
    path: 'truck-search',
    loadChildren: () => import('./pages/truck-search/truck-search.module').then(m => m.TruckSearchPageModule)
  },
  {
    path: 'load-search',
    loadChildren: () => import('./pages/load-search/load-search.module').then(m => m.LoadSearchPageModule)
  },
  {
    path: 'equipment-search',
    loadChildren: () => import('./pages/equipment-search/equipment-search.module').then(m => m.EquipmentSearchPageModule)
  }








];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
